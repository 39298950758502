<template>
  <v-container fluid>
    <div class="d-flex justify-center" flat tile>
      <Workflow
        width="600"
        height="180"
        :steps="workflowSteps"
        :currentStep="workflowIndex"
        :labelWidth="200"
        :lineWidth="140"
        title=""
      ></Workflow>
    </div>

    <!-- Titre de l'ihm -->
    <v-row class="mb-12" justify="center">
      <h1>Gestion des Praticiens</h1>
    </v-row>

     <!-- Ligne pour la présentaion de la liste -->
    <v-row justify="center" no-gutters>
      <v-col xl="6" lg="6" md="6" sm="6" xs="6">
        <v-row justify="center" no-gutters>

          <!-- First column -->
          <v-col>
            <div v-for="(item) in listOfItems" :key="item.name">
              <v-card tile elevation="0" :to="item.route">
                <div class="my-1 text-body-1 font-weight-regular">
                  {{ item.name }}
                </div>
              </v-card>
              <v-divider></v-divider>
            </div>
          </v-col>
          
        </v-row>
      </v-col>
    </v-row>


  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";
import * as tableListPractitioners from "@/views/practitioners/tableListPractitioners.js";


export default {
  components: { Workflow },
  mixins: [WorkflowMixin],

  mounted() {
    // Initialisation du worflow
    this.initSteps();

    this.listOfItems = tableListPractitioners.getTableList();
  },

  data() {
    return {
      // Liste des pages à afficher
      listOfItems: null,

    };
  },

  methods: {
    // Initialisation des étapes du workflow en fonction des droits de l'utilisateur
    initSteps() {
      this.addStepForWorkflow("Choisir un périmètre");
      this.addStepForWorkflow("Consulter la configuration");
    },
  }, // END METHODS
};
</script>

<style></style>
 