/**
 * Liste des pages accessibles depuis le vue home de Praticiens
 */
 import { defines } from "../../router/defines.js";
 import { hasOneOfRole } from "../../service/role_service.js";
 
 
 export const tableListPractitioners = [
   {
     // La consultation / modification d'une fiche praticien
     name: "Fiche praticien",
     route: defines.practitionerfile.view.path,
     roles: [ defines.practitionerfile.view.roles, ], 
   },
   {
     // L'ajout d'un praticien
     name: "Créer un praticien",
     route: defines.createpractitioner.view.path,
     roles: [ defines.createpractitioner.view.roles, ], 
   },
   {
     // La consultation / modification d'une expertise médicale
     name: "Expertises médicales",
     route: defines.medicalexpertises.table.path,
     roles: [ defines.medicalexpertises.table.roles, ], 
   },
   
 ]
 
 
 /**
  * Retourne la liste des pages consultables pour les pages praticiens
  * en fonction des droits applicatifs de l'utilisateur
  */
 export function getTableList() {
   let table = [];
 
   // Parcours les tables a affiché
   for (let item of tableListPractitioners) {
     // Vérifie que l'utilisateur as le droit d'accéder à cette page.
     if (hasOneOfRole(item.roles)) {
       table.push(item);
     }
   }
 
   return table;
 }